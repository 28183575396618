import React from "react";
import ReactMarkdown from "react-markdown";

import * as SShared from "../styles/shared";
import * as SPages from "./page.styles";

const S = { ...SShared, ...SPages };

const IntroBlock = ({ title, intro, titleEl, introEl, headerEl, ...props }) => (
  <S.PageHeader as={headerEl} {...props}>
    <S.PageCat as={titleEl}>{title}</S.PageCat>
    <S.PageTitle as={introEl}>
      <ReactMarkdown components={{ p: React.Fragment }}>{intro}</ReactMarkdown>
    </S.PageTitle>
  </S.PageHeader>
);

IntroBlock.defaultProps = {
  headerEl: "header",
  titleEl: "h1",
  introEl: "p",
};

export default IntroBlock;
