import React, { useState } from "react";
import _get from "lodash.get";
import fetch from "isomorphic-fetch";

import { animateScroll } from "react-scroll";
import { useForm, Controller } from "react-hook-form";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fadeIn } from "app/styles/animations";

import AnchorLink from "app/components/anchor-link";
import { CheckIcon } from "app/components/icons";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/contact-form.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const { control, handleSubmit, formState, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);

      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...data,
        }),
      });

      const { status } = response;

      if (status !== 200) {
        const message = await response.text();
        console.error(message);
        setNetworkError(true);
        setSubmitting(false);
        return;
      }

      setSubmitting(false);
      setAccepted(true);
      reset();

      animateScroll.scrollTo(100, {
        duration: 250,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    } catch (e) {
      console.error(e);
      setNetworkError(true);
      setSubmitting(false);

      animateScroll.scrollTo(100, {
        duration: 250,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  return (
    <S.Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        name="contact"
        data-netlify="true"
        noValidate={true}
        css={S.form}
      >
        <AnchorLink name="contact-form" />

        {networkError && (
          <S.ErrorBox css={fadeIn}>
            <S.ErrorText>
              We were unable to submit your form. This was likely caused by a
              network error and is not something you can fix. You can try again
              later or contact us by email{" "}
              <a href="mailto:wsi@nfte.com">wsi@nfte.com</a> or phone{" "}
              <a href="tel:1-212-232-3333">212-232-3333</a>.
            </S.ErrorText>
          </S.ErrorBox>
        )}

        {!accepted && (
          <S.FieldsWrap isSubmitting={isSubmitting}>
            <S.MaterialUIStyles />
            <input type="hidden" name="form-name" value="contact" />

            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!_get(formState, "errors.name")}
                  helperText={_get(formState, "errors.name.message")}
                  label="*Name"
                  css={S.halfField}
                />
              )}
              rules={{ required: "Required" }}
            />

            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!_get(formState, "errors.email")}
                  helperText={_get(formState, "errors.email.message")}
                  label="*Email"
                  type="email"
                  css={S.halfField}
                />
              )}
              rules={{
                required: "Required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Please enter a valid email address.",
                },
              }}
            />

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!_get(formState, "errors.phone")}
                  label="Phone Number"
                  type="tel"
                  css={S.halfField}
                />
              )}
            />

            <Controller
              name="role"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} label="Interest" select css={S.halfField}>
                  <MenuItem value={"participate"}>Participate</MenuItem>
                  <MenuItem value={"sponsor"}>Sponsor</MenuItem>
                </TextField>
              )}
            />

            <div css={S.fullField}>
              <Controller
                name="message"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!_get(formState, "errors.message")}
                    helperText={_get(formState, "errors.message.message")}
                    label="*Message"
                    multiline
                    rows="12"
                    fullWidth
                  />
                )}
              />
            </div>
          </S.FieldsWrap>
        )}

        {accepted && (
          <S.ThankYouBox css={fadeIn}>
            <S.ThankYouText>
              Thank you! We’ll get back to you shortly.
            </S.ThankYouText>
          </S.ThankYouBox>
        )}

        {!!accepted && (
          <S.SubmitBtn
            as={"button"}
            onClick={() => {
              setAccepted(false);
            }}
          >
            <CheckIcon />
          </S.SubmitBtn>
        )}

        {!accepted && (
          <S.SubmitBtn
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
            as={"button"}
            type={"submit"}
          >
            {isSubmitting && <CircularProgress />}
            {!isSubmitting && "submit"}
          </S.SubmitBtn>
        )}
      </form>
    </S.Container>
  );
};

export default ContactForm;
