import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import ContactForm from "app/components/contact-form";
import IntroBlock from "app/components/intro-block";
import Layout from "../components/layout";
import SEOMeta, { seoField } from "app/components/seo";

const ContactUs = ({ title, intro, ...props }) => (
  <Layout>
    <SEOMeta title={_get(props, "title")} {..._get(props, "seo")} />
    <article>
      <IntroBlock title={title} intro={intro} />
      <ContactForm />
    </article>
  </Layout>
);

export default ContactUs;

export const contactFragment = graphql`
  fragment ContactPage on ContentJson {
    ...SEO
    title
    intro
  }
`;

export const contactFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
    hint: "This field is used only in the admin panel",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "text",
    required: false,
  },
];
