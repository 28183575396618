import styled from "@emotion/styled";
// import { rem } from "../styles/utils";

import { achorLinkOffset } from "./header.styles";

export const ALRoot = styled.div`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
`;

export const ALTarget = styled.a`
  position: absolute;
  ${achorLinkOffset};
  left: 0;
`;
