import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { withStyles } from "@material-ui/core/styles";
import { rem, colors, fontSize, fontWeights } from "../styles/utils";
import { H1 } from "../styles/shared";
import { desktop } from "../styles/breakpoints";

import Btn from "./btn";
import { fadeZoomOut } from "app/styles/animations";

const { mint, black, white, red } = colors;

export const MaterialUIStyles = withStyles(() => ({
  "@global": {
    ".MuiInput-underline": {
      "&::before": {
        borderColor: black,
      },
    },
    ".MuiFormControl-root": {
      display: "flex",
      marginBottom: rem(50),
    },
    ".MuiFormLabel-root": {
      position: "absolute",
      top: rem(6),
      left: rem(16),
      fontSize: rem(12),
      lineHeight: 12 / 20,
      color: black,
      zIndex: 10,
    },
    ".MuiInputLabel-shrink": {
      transform: `translateY(${rem(-5)})`,
      fontWeight: fontWeights.bold,
    },
    ".MuiInputBase-root": {
      padding: 0,
      borderRadius: 0,
      backgroundColor: mint,
      color: black,
    },
    ".MuiInputBase-input": {
      color: black,
      padding: `${rem(10)} ${rem(16)}`,
      height: rem(40),
      boxSizing: "border-box",
    },
    ".Mui-focused": {
      color: `${black} !important`,

      "&::before, &::after": {
        borderColor: `${black} !important`,
      },
    },
    ".Mui-Error": {
      color: red,
    },
    ".MuiFormHelperText-root.Mui-error": {
      // position: "absolute",
      // top: "100%",
      paddingLeft: rem(16),
    },
    ".MuiInput-inputMultiline": {
      minHeight: rem(280),
    },
    ".MuiUnderline": {
      "&::before": {
        borderColor: black,
      },
    },
    ".MuiCircularProgress-colorPrimary": {
      color: white,
    },
  },
}))(() => null);

export const form = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s ease-out;
  ${({ isSubmitting }) => isSubmitting && fadeZoomOut};

  @media (${desktop}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const halfField = css`
  position: relative;
  width: 100%;

  @media (${desktop}) {
    width: calc(50% - ${rem(20)});
  }
`;

export const fullField = css`
  position: relative;
  width: 100%;
`;

export const FieldLabel = styled.label`
  position: absolute;
  bottom: 0;

  @media (${desktop}) {
    position: absolute;
    bottom: ${rem(20)};
  }
`;

const spinning = css`
  margin-top: ${rem(-200)};
`;

export const SubmitBtn = styled(Btn)`
  margin: ${rem(40)} auto ${rem(200)};
  transition: margin-top 0.5s ease-out;
  ${({ isSubmitting }) => isSubmitting && spinning};
`;

export const ThankYouBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${rem(30)} ${rem(60)};
  max-width: ${rem(840)};
  background-color: ${mint};
  margin: 0 auto ${rem(80)};

  @media (${desktop}) {
    min-height: ${rem(460)};
  }
`;

export const ThankYouText = styled(H1)`
  max-width: ${rem(500)};
  text-align: center;
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${rem(30)} ${rem(60)};
  max-width: ${rem(840)};
  border: 1px solid ${red};
  margin: 0 auto ${rem(80)};
`;

export const ErrorText = styled(H1)`
  font-size: ${rem(18)};
  font-weight: ${fontWeights.book};
  max-width: ${rem(500)};
  text-align: center;
  color: ${red};

  a {
    display: inline-block;
    border-bottom: 1px dashed ${red};

    &:hover {
      border-color: transparent;
    }
  }

  @media (${desktop}) {
    ${fontSize(22, 30)};
  }
`;
