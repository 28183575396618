import React from "react";
import _get from "lodash.get";

import * as S from "./anchor-link.styles";

const AnchorLink = (props) => (
  <S.ALRoot>
    <S.ALTarget id={_get(props, "name")} {...props} />
  </S.ALRoot>
);

export default AnchorLink;
